body {
  margin: 0;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.chart {
  background: 'black';
  flex: 1;
}

.map {
   flex: 1;
}

.map-container {
  height: 100%;
}

.sidebar {
  background-color: rgba(35, 55, 75, 0.9);
  color: #fff;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  border-radius: 4px;
}

.toolTip {
  border: 0.5px black solid;
  display: inline-block;
  background: white;
  padding: 1px 6px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 11px !important;
}

.logo {
  display: block;
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  width: 200px;
  margin: 24px;
}

.marker {
  max-width: 240px;
  display: flex;
  margin-bottom: 5em;
}

.mapboxgl-popup-content {
  background: none;
  box-shadow: none;
  color: #fff;
  text-shadow: 2px 2px #000;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 18px;
  line-height: 1.2;
  font-weight: bold;
  text-align: center;
  margin-bottom: 40px;
  text-transform: capitalize;
  height: 300px;
}

.mapboxgl-popup-content {
  text-transform: none;
}

.mapboxgl-popup-tip {
  display: none;
}

.station {
  height: 100px;
}
